import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import {Link} from "gatsby-plugin-intl";
import {useIntl} from "react-intl";
import {useMediaQuery} from "react-responsive";
import ChooseDealer from "../dealer/choose-dealer"

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
};

const ContactChooseDealer = ({location}) => {
  const intl = useIntl();
  return (
    <Layout>
      <SEO title="CHOOSE MY DEALER" />

      <Link to="/contact/how-can-help-you" className="black mb-4 mt-0 back-link single-page"><span className="icon icon-chevron"></span>{intl.formatMessage({ id: "generic.nav_buttons.back_button" })}</Link>

      <ChooseDealer navigateTo={'/contact/contact-my-dealer'}></ChooseDealer>

    </Layout>
  )
};

export default ContactChooseDealer
